
import { useRef, useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import spriteSvg from '../layout/sprite.svg'
import SiteLink from './SiteLink'
import Icon from '../utils/Icon'
import Schema from '../utils/Schema'

import '../styles/global.sass'

const SOCIALS = [
	['Facebook',	'facebook',	'https://facebook.com/comparamais.pt'],
	['Instagram',	'instagram','https://www.instagram.com/comparamais/'],
	['LinkedIn',	'linkedin',	'https://pt.linkedin.com/company/comparamais'],
]

const CAMPAIGN_IDS = ['utm_source', 'utm_medium', 'utm_campaign', 'wnr_source', 'aff_id', 'gclid', 'fbclid', 'msclickid']

function getDarkMode() {
	if (typeof window === 'undefined') return false
	const darkMode = window.localStorage.getItem('darkMode')
	return darkMode === 'true'
}

export const CAMPAIGN_IDS_STORAGE_KEY = 'comparamais-campaign-ids'


export default function Layout({ children, location }) {

	const [isOpenMenu, setOpenMenu] = useState(false)
	const menuRef = useRef(null)
	const [darkMode, setDarkMode] = useState(getDarkMode())

	const data = useStaticQuery(graphql`query Categories {
		allCategories(sort: { position: ASC } ) {
			nodes {
				id
				uri { url }
				label
			}
		}
		allLegalPages {
			nodes {
				id
				uri { url }
				label
			}
		}
		taxPage: infoPages(uri: { url: {eq: "/impostos/"}}) {
			uri { url }
			label
		}
		publicServicesPage: infoPages(uri: { url: {eq: "/servicos-publicos/"}}) {
			uri { url }
			label
		}
		savingsPage: infoPages(uri: { url: {eq: "/poupar-dinheiro/"}}) {
			uri { url }
			label
		}
	}`)

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const attrs = {}
		for (const [key, value] of searchParams.entries())
			if (CAMPAIGN_IDS.includes(key)) 
				attrs[key] = value
		if (Object.keys(attrs).length)
			localStorage.setItem(CAMPAIGN_IDS_STORAGE_KEY, JSON.stringify(attrs))
	}, [location.search])

	useEffect(() => {
		setOpenMenu(false)
	}, [location.pathname])

	useEffect(() => {
		if (!isOpenMenu) return;
		function handleClickOutside(event) {
			if (menuRef.current && !menuRef.current.contains(event.target))
				setOpenMenu(false)
		}
		document.addEventListener("mousedown", handleClickOutside)
		return () => document.removeEventListener("mousedown", handleClickOutside)
	}, [isOpenMenu, location.pathname])

	useEffect(() => {
		if (darkMode) document.body.classList.add('dark')
			else document.body.classList.remove('dark')
		try {
			window.localStorage.setItem('darkMode', darkMode)			
		} catch (_) {}
	}, [darkMode])

	return <>

		<div id='Navbar'>

			<SiteLink />
			
			<nav id='NavbarMenu' className={isOpenMenu ? 'isOpen' : 'isClosed'} ref={menuRef}>
				<button 
					aria-label='Menu' 
					aria-controls='navigation' 
					aria-expanded={isOpenMenu}
					onClick={() => setOpenMenu(v => !v)}
				>
					<Icon i='menu' />
				</button>
				<menu role='navigation'>
					{ data.allCategories?.nodes?.map(c => 
						<li key={c.id} to={c.uri.url}>
							<Link key={c.id} 
								to={c.uri.url}
								activeClassName='active'
								partiallyActive={true}
							>
								{ c.label.replace(/ ([^ ]+)$/, '\n$1') }
							</Link>
						</li>
					)}
				</menu>
			</nav>

			<address id='NavbarContact'>
				<Icon i='phone' />
				<a href="tel:210201170">210 201 170</a>
				<span>Seg. a Sex. 9h as 20h</span>
			</address>

		</div>

		<div id='DarkModeBanner' className={darkMode ? 'darkmode-on' : 'darkmode-off'}>
			<div id='DarkModeWrap'>
				<span id='DarkModeText'>Ativar {darkMode ? "Light" : "Dark"} Mode</span>
				<label id='DarkmodeToggle' aria-label="Ativar Dark/Light Mode">
					<input type='checkbox' 
						checked={darkMode}
						onChange={e => {
							setDarkMode(e.target.checked)
							try { 
								window.dataLayer?.push({ event: `DarkMode_${darkMode ? 'ON' : 'OFF'}` })
							} catch (_) {}
						}}
					/>
					<Icon i='lightmode' />
					<Icon i='darkmode' />
					<div className='switch' />
				</label>
			</div>
		</div>

		{children}

		<footer id='Footer'>

			<SiteLink />

			<button className='more-button' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
				Voltar ao topo <Icon i='up' />
			</button>

			<nav id='FooterLinks'>

				<ul className='loans'>{ data.allCategories?.nodes?.map(c => 
					<li key={c.id}><Link to={c.uri.url}>{c.label}</Link></li> 
				)}</ul>

				<ul className='info'>
					<li><Link to={data.taxPage?.uri.url}>{data.taxPage?.label}</Link></li>
					<li><Link to={data.publicServicesPage?.uri.url}>{data.publicServicesPage?.label}</Link></li>
					<li><Link to={data.savingsPage?.uri.url}>{data.savingsPage?.label}</Link></li>
				</ul>
				
				<ul className='legal'>
					<li><Link to='/sobre-nos/'>Sobre Nós</Link></li> 
					{ data.allLegalPages?.nodes?.map(p => 
						<li key={p.id}><Link to={p.uri.url} rel='noindex'>{p.label}</Link></li> 
					)}
				</ul>
			</nav>

			<figure id='FooterBank'>

				{/* <img src={BancoDePortugalSvg} width={204} height={96} alt='Autorizados pelo Banco de Portugal' loading='lazy' /> */}
				<svg xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 204 96" 
					width="204" 
					height="96"  
					alt="Autorizados pelo Banco de Portugal"
				>
					<use href={`${spriteSvg}#banco-de-portugal`} />
				</svg>

				<figcaption>
					Autorizados pelo Banco de Portugal<br />
					<strong>Intermediário de crédito nº00004514</strong>
				</figcaption>
			</figure>

			<section id='FooterSocials'>
				Segue-nos nas redes sociais<br />
				<ul id='FooterSocials'>
					{ SOCIALS.map(([label, icon, url]) =>
						<li key={label}>
							<a href={url} rel='nofollow noopener noreferrer'>
								<Icon i={icon} /><span>{label}</span>
							</a>
						</li>
					)}					
				</ul>
			</section>

			<section id='FooterCopy'>
				<p>© 2024 para Comparamais.pt. Leap Centre, Escritório E.22, 1250-091 Lisboa, Portugal</p>
			</section>

			<Schema json={{
				'@type': 'Organization',
				name: 'Comparamais.pt',
				url: 'https://www.comparamais.pt/',
				telephone: '+351210201170',
				address: {
					'@type': 'PostalAddress',
					streetAddress: 'Leap Centre, Escritório E.22',
					addressLocality: 'Lisboa',
					postalCode: '1250-091',
					addressCountry: 'PT',
				},
			}} />

		</footer>

	</>
}
